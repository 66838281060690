
.game {
  display: grid;
  margin-left: 5%;
  min-width: 1250px;
  grid-template-rows: 100px 750px 100px;
  grid-template-columns: 400px 750px 400px;
  grid-template-areas: 
    "inputone title inputtwo"
    "playerone board playertwo"
    ". footer .";
  justify-items: center;
}

.title {
  grid-area: title;
}

.input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.board {
  display: flex;
  grid-area: board;
  min-width: 750px;
  min-height: 750px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.player {
  padding: 10px;
  min-width: 300px;
  box-sizing: border-box;
}

.footer {
  grid-area: footer
}