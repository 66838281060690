.playercard {
  display: grid;
  width:400px;
  height: 100%;
  grid-template-rows: repeat(6, 1fr);
  grid-template-areas:
    "player"
    "pawns"
    "knights"
    "bishops"
    "rooks"
    "queens";
  border: 1px solid black;
}

.card{
  display: flex;
  grid-area: player;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px;
}

.player{
  margin: 27px;
}

.one {
  background-color: blanchedalmond;
  color: #565656;
}

.two {
  background-color: #565656;
  color: blanchedalmond;
}


.color {
  display: flex; 
  font-size: 30px; 
  width: 60px;
  height: 60px;
  border: 1px solid black;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.pieces {
  display: flex;
  justify-content: center;
}

.piece {
  height: 50px;
  width: 50px;
} 
