.square {
  display: flex;
  width: 90px;
  height: 90px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.white{
  background-color: blanchedalmond;
}

.black {
  background-color: #565656;
}

.selected {
  border: 5px solid #05B9A7;
  opacity: 0.7;
}